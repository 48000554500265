export enum EventTypeEnum {
    KEYDOWN = 'keydown',
    KEYUP = 'keyup',
    RESIZE = 'resize',
}

export enum KeyEnum {
    ESCAPE = 'Escape',
    ESC = 'Esc',
    ENTER = 'Enter',
    ESCAPE_CODE = 27,
}
